import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import useAxios from 'axios-hooks'
import { useMemo, useState } from 'react'
import {
  DatabaseSummary,
  PumpSummary,
  StationSummary,
} from '../../types/PumpStations'
import OverlayLoading from '../components/OverlayLoading'
import Station from '../components/Station'
import { useUser } from '../context/userContext'
import { useInterval } from '../lib/hooks'

const Index = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [queryPage, setQueryPage] = useState(1)
  const [queryMaxPage, setQueryMaxPage] = useState<number>(1)
  const [queryString, setQueryString] = useState('')
  const [inputBuffer, setInputBuffer] = useState('')
  const { user } = useUser()

  const generatePaginationElems = (): JSX.Element[] => {
    const startPage = Math.max(1, queryPage - 3)
    const endPage = Math.min(queryMaxPage, queryPage + 3)

    const pageArray: number[] = []
    for (let x = startPage; x <= endPage; x++) {
      pageArray.push(x)
    }

    return pageArray.map((i) => {
      if (i === queryPage) {
        return (
          <a
            key={i}
            href="#"
            aria-current="page"
            className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
          >
            {i}
          </a>
        )
      } else {
        return (
          <a
            key={i}
            href="#"
            className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
            onClick={(e) => {
              setQueryPage(i)
              setLoading(true)
            }}
          >
            {i}
          </a>
        )
      }
    })
  }

  const generatePaginationElem = (): JSX.Element => {
    if (!querySummary) {
      return <></>
    }
    const page = querySummary.page ? querySummary.page : 0
    const pageSize = querySummary.pageSize ? querySummary.pageSize : 0
    const total = querySummary.total ? querySummary.total : 0
    return (
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 m-6 rounded">
        <div className="flex flex-1 justify-between sm:hidden">
          <a
            href="#"
            onClick={(e) => {
              if (queryPage > 1) {
                setQueryPage(queryPage - 1)
                setLoading(true)
              }
            }}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            onClick={(e) => {
              if (
                queryMaxPage !== null &&
                queryMaxPage !== undefined &&
                queryPage < queryMaxPage
              ) {
                setQueryPage(queryPage + 1)
                setLoading(true)
              }
            }}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{page * pageSize + 1}</span>{' '}
              to{' '}
              <span className="font-medium">
                {total < pageSize ? total : page * pageSize + pageSize}
              </span>{' '}
              of <span className="font-medium">{querySummary?.total}</span>{' '}
              results
            </p>
          </div>
          <div>
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <a
                href="#"
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                onClick={(e) => {
                  if (queryPage > 1) {
                    setQueryPage(queryPage - 1)
                    setLoading(true)
                  }
                }}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {generatePaginationElems()}
              <a
                href="#"
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                onClick={(e) => {
                  if (
                    queryMaxPage !== null &&
                    queryMaxPage !== undefined &&
                    queryPage < queryMaxPage
                  ) {
                    setQueryPage(queryPage + 1)
                    setLoading(true)
                  }
                }}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    )
  }

  const generateSearchElem = (): JSX.Element => {
    const handleSubmit = () => {
      setLoading(true)
      setQueryString(inputBuffer)
      setQueryPage(1)
    }

    return (
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 m-6 rounded">
        <div className="grid grid-cols-5 w-full">
          <input
            className="col-span-4 border-none mr-1"
            type={'text'}
            value={inputBuffer}
            placeholder="Search"
            onChange={(e) => {
              setInputBuffer(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
          />

          <button
            className="grid-span-1 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
            onClick={handleSubmit}
          >
            Search
          </button>
        </div>
      </div>
    )
  }

  const baseURI = `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/pump_stations`

  const [
    { data: stationData, loading: stationDataLoading, error: stationDataError },
    refetch,
  ] = useAxios(
    queryString === ''
      ? baseURI + '?page=' + (queryPage - 1)
      : baseURI +
          '?name=' +
          encodeURIComponent(queryString) +
          '&page=' +
          (queryPage - 1),
    {}
  )

  const querySummary: DatabaseSummary | null = useMemo(() => {
    try {
      if (stationData) {
        const dbSummary = {
          total: stationData.total,
          pageSize: stationData.pageSize,
          page: stationData.page,
          message: stationData.message,
          data: stationData.data
            ? stationData.data.map((item: any) => {
                const pumpData: PumpSummary[] = item.pumps.map((item: any) => {
                  const pump: PumpSummary = {
                    deviceId: item.device_id,
                    deviceName: item.name,
                    mode: item.mode,
                    online: item.online,
                    isFaulted: item.is_faulted,
                    outCurrent: item.out_current,
                    outFrequency: item.out_frequency,
                    tankLevel: item.tank_level,
                  }
                  return pump
                })

                const station: StationSummary = {
                  edgeNodeId: item.edge_node_id,
                  edgeNodeName: item.name ? item.name : '',
                  online: item.online,
                  isGeneratorPowered: item.is_generator_powered,
                  isIrrigation: item.is_irrigation,
                  dischargeTankLevel: item.discharge_tank_level,
                  lastReported: item.reported,
                  latitude: item.location ? item.location.latitude : undefined,
                  longitude: item.location
                    ? item.location.longitude
                    : undefined,
                  signalStrength: item.signal_strength,
                  softwareVersion: item.software_version,
                  pumps: pumpData,
                  reportingInterval: item.reporting_interval,
                  relayStatus: item.relayStatus,
                  temperature: item.temperature,
                }
                return station
              })
            : [],
        }
        setQueryMaxPage(Math.ceil(dbSummary.total! / dbSummary.pageSize!))
        setLoading(false)
        return dbSummary
      }
      return null
    } catch (error) {
      console.error(error)
      return null
    }
  }, [stationData])

  useInterval(refetch, 10000)

  if (loading) {
    return <OverlayLoading />
  }

  if (stationDataError) {
    return (
      <div className="text-bold text-2xl m-20">
        There was an error loading your data. Please refresh the page to try
        again.
      </div>
    )
  }

  if (!querySummary?.data) {
    return <div> </div>
  }

  return (
    <div className="w-full h-full">
      <div className="mx-auto max-w-3xl">
        {generateSearchElem()}
        {querySummary.data.map((station) => (
          <Station key={station.edgeNodeId} station={station} />
        ))}
        {generatePaginationElem()}
      </div>
    </div>
  )
}

export default Index
